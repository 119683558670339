.monitoring-services {
    height: 100vh;    
    width: 100%;
    position: relative;
    max-width: 1230px;
    margin: 0 auto;
    padding: 52px 15px;
    padding-bottom: 7vh;
    z-index: 1;
}
.monitoring-services__title {
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 46px;
    line-height: 54px;
    color: #1B1045;
    text-align: center;
}
.monitoring-services__text {
    margin: 15px auto;
    margin-bottom: 0;
    width: 520px;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #5A36A5;
}
.monitoring-services__list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 40px 0 0 0;
    padding: 0;
    padding-right: 50px;
    height: 592px;

    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #5A36A5;
}

.monitoring-services__list-item-text {
    margin: 0;
    margin-top: 24px;
}

.monitoring-services__list-item-title {
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 40px;
    color: #1B1045;
}
.monitoring-services__list-item {
    position: relative;
    width: 48.8%;
    min-height: 150px;
    padding-left: 180px;
    // margin-bottom: 50px;
}

.monitoring-services__list-img-wrap {
    position: absolute;
    width: 140px;
    height: 140px;
    top: 10px;
    left: 0;
    border-radius: 50%;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(91, 54, 166, 0.16);
    display: flex;
    justify-content: center;
    align-items: center;
}

.monitoring-services__explanation {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 22px;
    color: #5A36A5;
    position: absolute;
    bottom: 7vh;
    left: 180px;
}