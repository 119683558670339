.satellite-monitoring-of-transport {
    position: relative;
    width: 100%;
    max-width: 1230px;
    padding: 0 65px;
    padding-bottom: 96px;
    margin: 0 auto;

    height: 100vh;
    min-height: 940px;

    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #5A36A5;

    overflow: hidden;

    &__title {
        margin: 0 auto;
        margin-top: 60px;
        width: 640px;

        font-weight: bold;
        font-size: 36px;
        line-height: 40px;

        text-align: center;
        color: #1B1045;
    }

    &__list {
        margin: 0;
        margin-top: 60px;
        padding: 0;

    }

    &__price-list {
        margin: 0;
        margin-top: 60px;
        padding: 0;

        display: flex;
        justify-content: space-between;
        align-items: stretch;

        text-align: center;

    }

    &__price-title {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        color: #1B1045;

        min-height: 85px;
    }

    &__price-item {
        padding: 20px 25px;
        box-shadow: 0px 0px 20px rgba(91, 54, 166, 0.16);
        border-radius: 20px;
        width: 100%;
        max-width: 260px;
    }

    &__price-sum {
        font-weight: bold;
        font-size: 36px;
        line-height: 40px;
        text-align: center;
        color: #C56FE9;

        margin-top: 27px;
        min-height: 96px;

        span {
            display: block;
            width: 100%;

            font-weight: 600;
            font-size: 22px;
            line-height: 26px;
            text-align: center;
            color: #C56FE9;
        }
    }

    &__price-text {
        margin-top: 10px;
    }

    &__subtext {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 54px;

        margin: 0;
        text-align: center;
    }
}