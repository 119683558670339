.benefits-of-monitoring-services {
    width: 100%;
    min-height: 940px;
    height: 100vh;
    position: relative;
    overflow: hidden;

    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    text-align: justify;
    color: #5A36A5;
    background: url("../../../../public/img/notebook.jpg") no-repeat bottom left;
    background-size: 45vw auto;

    &__wrap {
        width: 100%;
        max-width: 1230px;
        margin: 0 auto;
        padding: 0 15px;
        padding-right: 50px;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
    }

    &__title {
        margin: 0;
        margin-top: 50px;
        font-style: normal;
        font-weight: bold;
        font-size: 46px;
        line-height: 50px;
        color: #1B1045;
        text-align: left;
        width: 600px;
    }

    &__text {
        margin: 0;
        margin-top: 50px;
        width: 600px;
    }

    &__list {
        padding: 0;
        margin: 0;
        margin-top: 50px;
        list-style: none;
        // align-content: space-between;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 556px;
        width: 600px;

        box-shadow: 0px 0px 20px rgba(91, 54, 166, 0.16);
        border-radius: 20px;
        background: url('../../../../public/img/list-line.svg') no-repeat center;
    }
    &__item {
        width: 50%;
        height: 278px;
    }

    &__item-img {
        display: block;
        margin: 0 auto;
        margin-top: 55px;
        margin-bottom: 30px;

    }
    &__item-text {
        width: 144px;
        margin: 0 auto;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #1B1045;
    }
}