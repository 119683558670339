.benefits-gps {
    width: 100%;
    position: relative;
    max-width: 1230px;
    padding: 0 13%;
}


.benefits-gps__list {
    height: 275px;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 50px;
}

.benefits-gps__list-item {
    padding: 0;
    margin: 0;
    width: 354px;
    min-height: 45px;
    padding-left: 78px;
    position: relative;
    margin-bottom: 20px;
}

.benefits-gps__list-img-wrap {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 78px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.benefits-gps__list-item-text {
    margin: 0;
}

.benefits-gps__title {
    margin: 0;
    margin-top: 45px;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    color: #1B1045;
}