.how-to-control-monitoring {
    width: 100%;
    max-width: 1230px;
    padding: 0 65px;
    padding-bottom: 10px;
    margin: 0 auto;

    height: 100vh;
    min-height: 940px;

    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #5A36A5;

    overflow: hidden;

    &__title {
        margin: 0 auto;
        margin-top: 50px;
        font-style: normal;
        font-weight: bold;
        font-size: 46px;
        line-height: 50px;

        color: #1B1045;
    }

    &__first-list-wrap {
        position: relative;
        margin-top: 60px;
        overflow: hidden;
        width: 100%;
        min-height: 300px;

        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: row;

        &::before {
            content: '';

            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);

            width: 3px;

            background: #C56FE9;
            opacity: 0.3;
        }
    }

    &__first-list {
        width: 47%;

        min-height: 300px;

        text-align: left;
    }

    &__first-item {
        
    }


    &__first-img-wrap {
        width: 43%;
        min-height: 300px;
    }

    &__first-img {
        width: 100%;
        height: 300px;
        margin: auto;
    }


    &__second-list {
        margin: 0;
        margin-top: 70px;
        list-style-type: none;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__second-item {
        width: 240px;
    }

    &__second-item-img {
        width: 100%;
        border-radius: 100%;
    }

    &__second-item-text {
        margin: 0;
        padding-top: 20px;
    }
}