.button {
    outline: none;
    padding: 12px 47px;

    background: #5A36A5;
    box-shadow: 0px 0px 16px rgba(197, 111, 232, 0.5);
    border: none;
    border-radius: 99px;
    color: #fff;

    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
}

.footer {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    padding: 0 50px;
    padding-top: 50px;

    padding-bottom: 380px;
    height: 100vh;

    &__title {
        margin: 0;

        font-style: normal;
        font-weight: bold;
        font-size: 46px;
        line-height: 50px;
        text-align: center;
        color: #1B1045;
    }

    &__subtext {
        margin: 0;
        margin-top: 20px;

        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #5A36A5;
    }

    &__form {
        margin: 0 auto;
        margin-top: 35px;
        padding-bottom: 80px;
        border-bottom: 3px solid #C56FE9;
        width: 840px;

        display: flex;
        align-items: stretch;
        align-content: stretch;
        justify-content: space-between;
    }

    &__form-item {
        outline: none;
        margin: 0;
        position: relative;

        span {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 52px;
            font-size: 18px;
            line-height: 22px;
            color: #C6BEE3;
            transition: all 0.5s ease;
        }

        &.active {
            span {
                top: -20px;
                left: 15px;
                font-size: 15px;
            }
        }
        input {
            outline: none;
            padding-left: 52px;
            height: 50px;
            width: 300px;
            background: #F7F6FB;
            border: none;
            border-radius: 100px;
        }

        &.name {
            position: relative;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0px;
                width: 44px;
                background: url('../../../../public/img/user.svg') no-repeat center right;
            }
        }
        &.phone {
            position: relative;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 44px;
                background: url('../../../../public/img/phone.svg') no-repeat center right;
            }
        }
    }

    // &__button {
        // margin-left: auto;
        // margin-right: 0;
    // }

    &__info {
        padding-top: 60px;
        padding-bottom: 30px;

        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: flex-end;
        color: #5A36A5;

        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: space-between;
    }
    &__navigation {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        a {
            margin-bottom: 10px;
        }
    }

    &__info-text {
        margin: 0;
        margin-bottom: 20px;

        text-align: left;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 19px;
        text-transform: uppercase;
        color: #1B1045;
        padding-left: 35px;
    }

    &__info-mail {
        position: relative;

        margin: 0;
        margin-bottom: 10px;

        font-weight: normal;
        font-size: 18px;
        line-height: 23px;
        color: #5A36A5;
        padding-left: 35px;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 35px;
            background: url('../../../../public/img/mail.svg') no-repeat center left;

        }
    }

    &__info-feedback {
        position: relative;

        margin: 0;
        margin-bottom: 10px;
        font-weight: 900;
        font-size: 20px;
        line-height: 25px;
        color: #5A36A5;
        padding-left: 35px;

        &::before {
            content: '';
            position: absolute;
            top: 6px;
            left: 0;
            bottom: 0;
            width: 35px;
            background: url('../../../../public/img/phone.svg') no-repeat top left;
        }

        a {
            display: block;

            font-weight: normal;
            font-size: 18px;
            line-height: 23px;
            text-decoration-line: underline;
            color: #C56FE9;
        }
    }

    &__copyright {
        margin: 0;
        width: 100%;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #C6BEE3;
    }

    &__map {
        position: absolute;
        bottom: 0;
        left: 50px;
        right: 50px;
        height: 370px;
    }
}