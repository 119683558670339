.work-with-the-system {
    width: 100%;
    min-height: 940px;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background: #F7F6FB;

    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #5A36A5;

    &__wrap {
        width: 100%;
        max-width: 1230px;
        margin: 0 auto;
        padding: 0 15px;
        padding-right: 200px;
    }

    &__title {
        margin: 0;
        margin-top: 50px;
        font-style: normal;
        font-weight: bold;
        font-size: 46px;
        line-height: 50px;
        color: #1B1045;
        text-align: center;
        width: 100%;
    }

    &__list {
        list-style-type: none;
        margin: 0;
        margin-top: 55px;
        padding: 0;
        padding-left: 218px;
        min-height: 713px;
        background: url('../../../../public/img/list-img.svg') no-repeat top left;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &__item {
        width: 557px;
    }
}