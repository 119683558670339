.header {
    position: relative;
    display: flex;
    width: 100%;
    // max-width: 1230px;
    // margin: 0 auto;
    // padding: 0 15px;
    min-height: 77px;
    height: 8.2vh;

    justify-content: space-between;
    align-items: flex-end;


    font-family: 'SourceSansPro';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
}

.header__logo {
    width: 181px;
    height: 57px;
    background: url('../../../../public/img/logo.svg') no-repeat center;
    background-size: contain;
}

.header__nav {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    a {
        position: relative;
        display: block;
        margin-left: 50px;

        &:hover {
            &::after {
                content: '';
                position: absolute;
                bottom: -9px;
                left: 0;
                right: 0;
                border: 1px solid #FFFFFF;
            }
        }

        &.active {
            &::after {
                content: '';
                position: absolute;
                bottom: -9px;
                left: 0;
                right: 0;
                border: 1px solid #FFFFFF;
            }
        }
    }
}