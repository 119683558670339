.monitoring-system-installation-time {
    position: relative;

    width: 100%;
    max-width: 1230px;
    padding: 0 65px;
    margin: 0 auto;
    height: 100vh;
    min-height: 940px;

    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #5A36A5;

    overflow: hidden;

    background: url('../../../../public/img/monitoring-system-installation-time-bg-1.jpg') no-repeat center 25px;

    &__title {
        font-style: normal;
        font-weight: bold;
        font-size: 46px;
        line-height: 50px;
        text-align: center;
        color: #1B1045;

        margin: 0 auto;
        margin-top: 50px;
        width: 640px;
    }

    &__subtext {
        margin-top: 60px;
        text-align: center;

        b {
            display: block;
            width: 100%;

            text-align: center;
            font-weight: 600;
            font-size: 22px;
            line-height: 26px;
            color: #1B1045;
        }
    }

    &__list-title {
        margin-top: 60px;
    }

    &__list {
        // background-size: contain;
    }

    &__item {
        margin-top: 20px;
        width: 360px;
    }

    &__text {
        position: absolute;
        bottom: 0;
        left: 50px;
        right: 50px;

        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        color: #5A36A5;

        padding: 45px 192px;
        height: 376px;
        background: url('../../../../public/img/monitoring-system-installation-time-bg-2.jpg') no-repeat;
        background-size: contain;
    }
}