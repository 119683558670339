.features-and-benefits-of-gps {
    height: 100vh;   
    width: 100%;
    position: relative;
    background: #F7F6FB;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #5A36A5;
}