.what-is-controlled {
    position: relative;

    width: 100%;
    height: 100vh;
    min-height: 940px;

    background: #F7F6FB;

    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #5A36A5;

    overflow: hidden;

    &__wrap {
        width: 100%;
        height: 100%;
        max-width: 1230px;
        padding: 0 65px;
        margin: 0 auto;
    }

    &__title {
        font-weight: bold;
        font-size: 46px;
        line-height: 50px;
        text-align: center;
        color: #1B1045;

        margin: 0 auto;
        margin-top: 50px;
        width: 560px;
    }

    &__info-text {
        width: 540px;
        margin: 0 auto;
        margin-top: 55px;


        display: flex;
        justify-content: space-between;

        span {
            display: block;
            width: 100%;

            font-weight: bold;
            font-size: 60px;
            line-height: 64px;
            text-align: center;
            color: #5A36A5;       
        }

        p {
            width: 168px;

            font-weight: bold;
            font-size: 30px;
            line-height: 34px;
            text-align: center;
            color: #1B1045;
        }
    }

    &__list {
        width: 100%;
        margin-top: 54px;

        display: flex;
        justify-content: space-between;
        align-content: flex-start;
        align-items: flex-start;

        flex-wrap: wrap;
    }

    &__item {
        position: relative;

        width: 32%;
        margin-bottom: 20px;

        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(91, 54, 166, 0.16);
        border-radius: 20px;

        overflow: hidden;
    }
    
    &__item-img-wrap {

        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        width: 80px;
        height: 80px;

        background: #F7F6FB;
        border: 3px solid #5A36A5;
        border-radius: 20px;
    }

    &__item-title {
        display: flex;
        align-items: center;

        font-weight: bold;
        font-size: 30px;
        line-height: 34px;
        color: #1B1045;

        min-height: 80px;
        padding-left: 100px;
        padding-right: 50px;

    }

    &__item-text {
        min-height: 185px;
        padding: 0 30px;

        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}