.our-advantages {
    width: 100%;
    min-height: 940px;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background: #ffffff;

    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #5A36A5;

    &__wrap {
        width: 100%;
        max-width: 1230px;
        margin: 0 auto;
        padding: 0 15px;
        padding-right: 50px;
    }

    &__title {
        margin: 0;
        margin-top: 50px;
        font-style: normal;
        font-weight: bold;
        font-size: 46px;
        line-height: 50px;
        color: #1B1045;
        text-align: center;
        width: 100%;
    }

    &__slider {
        position: relative;
        margin-top: 60px;
        overflow: hidden;
        width: 100%;
        min-height: 272px;
    }

    &__slider-content-title {
        margin: 0;
        margin-top: 66px;
        text-align: right;

        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
    }

    &__slider-content {
        padding-left: 50px;
        width: 47%;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        text-align: justify;
        color: #5A36A5;
        min-height: 272px;
    }
    &__slider-content-text {
        margin: 20px 0;
    }
    &__slider-item {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: row;

        &::before {
            content: '';

            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);

            width: 3px;

            background: #C56FE9;
            opacity: 0.3;
        }
    }


    &__slider-img-wrap {
        width: 43%;
        min-height: 272px;
    }

    &__slider-img {
        width: 100%;
        height: 272px;
        margin: auto;
    }

    &-dots {
        position: absolute;
        margin: 0;
        padding: 0;
        width: 47%;
        min-height: 50px;
        display: flex !important;
        flex-direction: row;
        
        justify-content: flex-end;
        align-items: flex-end;
        counter-reset: heading;
        list-style: none;
        top: 0;
        left: 0;
        color: #ffffff;
    
        li {
            margin: 0;
            position: relative;
        
            button {
                padding: 0;
                position: relative;
                transition-property: font-size;
                transition-duration: 0.05s;
                transition-timing-function: ease;
                transform-origin: bottom right;
                background: transparent;
                font-size: 0;
                border: none;
                outline: none;
                color: inherit;
                color: #5A36A5;
                opacity: 0.3;
                margin-left: 30px;

                &::after {

                    font-family: 'SourceSansPro';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 1;
                    text-align: right;
                    height: auto;
                    counter-increment: heading; /* Указываем идентификатор счетчика */
                    content: "0" counter(heading);
                    color: inherit;
                }
            }

            &.slick-active {
                button {
                    opacity: 1;
                    &::after {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 46px;
                        line-height: 1;
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        bottom: -10px;
                        left: 0;
                        right: 0;
                        height: 3px;
                        background: #1B1045;
                    }
                }
            }
        }
    }
    &__list {
        list-style-type: none;
        padding: 0;

        margin: 0;
        margin-top: 45px;
        padding: 0;

        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        counter-reset: heading;
    }
    &__list-title {
        margin: 0;
        margin-top: 120px;
        text-align: center;
        width: 100%;
    }

    &__list-item {
        padding-top: 110px;
        width: 16%;
        min-height: 180px;
        text-align: center;
        position: relative;
        b {
            display: block;
            text-align: center;
        }
        &::after {
            counter-increment: heading; /* Указываем идентификатор счетчика */
            content: counter(heading);
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            font-weight: normal;
            font-size: 60px;
            line-height: 64px;
            color: #5A36A5;

            width: 76px;
            height: 76px;
            border: 4px solid #C56FE9;
            border-radius: 50%;
        }
    }
}