.our-team {
    position: relative;

    width: 100%;
    max-width: 1230px;
    padding: 0 65px;
    margin: 0 auto;
    height: 100vh;
    min-height: 940px;

    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #5A36A5;

    overflow: hidden;

    background: url('../../../../public/img/our-team-bg.jpg') no-repeat center;
    background-position: 75% 50%;

    &__title {
        font-style: normal;
        font-weight: bold;
        font-size: 46px;
        line-height: 50px;
        text-align: center;
        color: #1B1045;

        margin: 0 auto;
        margin-top: 50px;
        width: 640px;
    }

    &__list {
        margin-top: 75px;
    }


    &__text {
        width: 455px;
        position: absolute;
        bottom: 110px;
        right: 190px;
    }
}