.monitoring-сapabilities {
    width: 100%;
    position: relative;
    max-width: 1230px;
    padding: 0 13%;
}

.monitoring-сapabilities__wrap {
}

.monitoring-сapabilities__title {
    margin: 0;
    margin-top: 50px;
    margin-bottom: 50px;
    font-style: normal;
    font-weight: bold;
    font-size: 46px;
    line-height: 50px;
    text-align: center;
    color: #1B1045;
}
.monitoring-сapabilities__list {
    margin: 0;
    padding: 0;
    height: 260px;

    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    // align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.monitoring-сapabilities__list-item {
    width: 383px;
    padding-left: 35px;
    position: relative;
    margin-bottom: 15px;
    min-height: 48px;


    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 38px;
        background: url("../../../../../public/img/list-tick.png") no-repeat left 5px;
    }
}

