@font-face {
    font-family: 'SourceSansPro';
    src: url('../public/fonts/SourceSansPro/SourceSansPro-Regular.woff2') format('woff2'),
        url('../public/fonts/SourceSansPro/SourceSansPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SourceSansPro';
    src: url('../public/fonts/SourceSansPro/SourceSansPro-Bold.woff2') format('woff2'),
        url('../public/fonts/SourceSansPro/SourceSansPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SourceSansPro';
    src: url('../public/fonts/SourceSansPro/SourceSansPro-Regular.woff2') format('woff2'),
        url('../public/fonts/SourceSansPro/SourceSansPro-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'SourceSansPro';
    src: url('../public/fonts/SourceSansPro/SourceSansPro-Bold.woff2') format('woff2'),
        url('../public/fonts/SourceSansPro/SourceSansPro-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'SourceSansPro';
    src: url('../public/fonts/SourceSansPro/SourceSansPro-Black.woff2') format('woff2'),
        url('../public/fonts/SourceSansPro/SourceSansPro-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'SourceSansPro';
    src: url('../public/fonts/SourceSansPro/SourceSansPro-SemiBold.woff2') format('woff2'),
        url('../public/fonts/SourceSansPro/SourceSansPro-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}