p {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;

  list-style-type: none;
}

li {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'SourceSansPro';
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  color: #ffffff;
}

html {
  box-sizing: border-box;
}

*, 
*::after,
*::before {
  box-sizing: inherit;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.list {
  li {
    padding-left: 35px;
    position: relative;
    margin-bottom: 15px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 38px;
        background: url("../public/img/list-tick.png") no-repeat left 5px;
    }
  }
}