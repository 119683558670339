.bonient-service {
    position: relative;

    width: 100%;
    height: 100vh;
    min-height: 940px;

    background: url('../../../../public/img/bonient-service-notebook.jpg') no-repeat left 200px;

    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #5A36A5;

    overflow: hidden;

    &__wrap {
        width: 100%;
        height: 100%;
        max-width: 1230px;
        padding: 0 65px;
        margin: 0 auto;

    }

    &__title {
        font-weight: bold;
        font-size: 46px;
        line-height: 50px;
        text-align: center;
        color: #1B1045;

        margin: 0 auto;
        margin-top: 50px;

        width: 560px;
    }

    &__list {
        width: 540px;
        margin-top: 80px;
        margin-left: auto;
        margin-right: 0;
        padding-right: 25px;
        padding-bottom: 25px;

        box-shadow: 0px 0px 20px rgba(91, 54, 166, 0.16);
        border-radius: 20px;
        background: #FFFFFF;

        counter-reset: heading;
    }

    &__item {
        position: relative;

        margin-top: 30px;
        padding-left: 117px;

        overflow: hidden;

        &::before {

            counter-increment: heading; /* Указываем идентификатор счетчика */
            content: "0" counter(heading);

            position: absolute;
            top: 0;
            left: 0;

            display: flex;
            justify-content: center;
            align-items: center;
            width: 80px;
            height: 80px;

            background: #F7F6FB;
            border: 3px solid #5A36A5;
            border-radius: 20px;

            font-style: normal;
            font-weight: bold;
            font-size: 46px;
            line-height: 50px;
        }
    }

    &__item-title {
        font-weight: bold;
        font-size: 30px;
        line-height: 34px;
        color: #1B1045;

        margin-top: 20px;
    }

    &__item-text {
        margin-top: 20px;
    }

    &__button {
        position: absolute;
        left: 50%;
        bottom: 8vh;

        transform: translateX(-50%);
    }

}