.statement {
    width: 100%;
    min-height: 940px; 
    height: 100vh; 
    position: relative;
    overflow: hidden;
    background:
    linear-gradient(to top, #2E1D53 0%, #2E1D53 25.77%, #342159 41.37%, #472A6B 59.69%, #58347C 86.14%, #613886 100%);

    &::after {
        content: '';
        display: block;
        position: absolute;
        height: 800px;
        width: 1200px;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
        margin: 0 auto;
        background: url('../../../../public/img/spot.png') no-repeat top left;
        background-size: contain;
        z-index: 2;
    }

    &::before {
        z-index: 3;
    }

    &__text {
        margin-top: 65px;
    }
}

.statement__wrap {
    height: 100%;    
    width: 100%;
    position: relative;
    display: flex;
    max-width: 1230px;
    margin: 0 auto;
    padding: 0 15px;
    padding-bottom: 7vh;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    z-index: 3;
}

.statement__content {
    margin: 0 auto;
    margin-left: 0;
    margin-top: 18.6vh;
    width: 40%;

    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;
}

.statement__title {
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 64px;
    span {
        display: block;
        width: 100%;
    }
}

.statement__contacts-info {
    position: absolute;
    bottom: 7vh;
    left: 15px;

    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
}

.statement__bell {
    border: none;
    background: transparent;
    padding: 0;
    text-decoration: underline;
    font-size: 18px;
    line-height: 23px;
    color: #ffffff;
}

.statement__phone {
    margin: 0;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 25px;
}

.statement__email {
    margin: 0;
    margin-bottom: 8px;
}

.statement--vehicle-monitoring {
    &::after {
        opacity: 0.7;
    }
    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        background: url('../../../../public/img/statement-vehicle-monitoring.jpg') no-repeat center;
        background-size: cover;
        opacity: 0.2;
    }
}

.statement--about-company {
    &::after {
        opacity: 0.6;
    }
    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        bottom: 0;

        width: 1230px;
        transform: translateX(-50%);

        background: url('../../../../public/img/statement-map.png') no-repeat;
        background-position: 50% 86%;
        background-blend-mode: color-dodge;
        opacity: 0.3;
        z-index: 1;
    }

    .statement {
        &__title {
            width: 722px;
        } 
    }
}
.statement--main-page {
    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        bottom: 0;

        width: 1230px;
        transform: translateX(-50%);
        
        transform: translateX(-50%);
        background: url('../../../../public/img/statement-hand.png') no-repeat;
        background-position: 96% 50%;
    }
}
