.slider-page {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &__track {
        overflow: hidden;
        position: relative;
    }

    .dots {
        position: fixed;
        margin: 0;
        padding: 0;
        width: 100%;
        max-width: 1230px;
        padding-right: 15px;
        display: flex;
        flex-direction: column;
    
        justify-content: flex-start;
        align-items: flex-end;
        align-content: flex-end;
        flex-wrap: nowrap;
        height: 249px;
        counter-reset: heading;
        list-style: none;
        top: 133px;
        left: 50%;
        transform: translateX(-50%);
        color: #ffffff;
        height: 0;

        li {
            width: auto;
            margin-bottom: 11px;
            display: flex;
            justify-content: flex-end;
            position: relative;
        
            button {
                padding: 0;
                position: relative;
                transition-property: font-size;
                transition-duration: 0.05s;
                transition-timing-function: ease;
                transform-origin: bottom right;
                background: transparent;
                font-size: 0;
                border: none;
                outline: none;
                color: inherit;
                min-width: 50px;
                min-height: 26px;
                &::after {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    font-family: 'SourceSansPro';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 26px;
                    text-align: right;
                    min-width: 40px;
                    height: auto;
                    counter-increment: heading; /* Указываем идентификатор счетчика */
                    content: "0" counter(heading);
                    color: inherit;
                    margin-left: auto;
                    margin-right: 0;
                }
            }
            &.active {
                button {
                    &::after {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 30px;
                        line-height: 26px;
                    }
                }
            }
        }
    }
    .dots--theme-purple {
        color: #5A36A5;
    }
}